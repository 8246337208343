import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'PerformanceOverTimeReportMixin',
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    translatePeriodColumnTitleIntoNiceName (sValue, sResolution) {
      const iYear = sValue.toString().substring(0, 4)
      let sReturnLabel = null

      if (sResolution === 'month') {
        const iMonth = parseInt(sValue.substring(5))
        sReturnLabel = this.$t('common.months.month' + iMonth + '_short')
        if (iMonth === 1) {
          sReturnLabel = sReturnLabel + ' ' + iYear
        }
      } else {
        sReturnLabel = iYear.toString()
      }

      return sReturnLabel
    },
    amountFormatter (value) {
      return value ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0) : 0
    },
    percentageFormatter (value) {
      if (typeof value === 'number') {
        return parseFloat(value).toFixed(1).toString() + '%'
      }
      return value
    },
    gomycell (key) {
      return `cell(${key})`
    },
    buildTableDataRows (sTableTypeToBuild, isLoaded, apiData, accountType, resolution) {
      if (!isLoaded) {
        return []
      }

      let bFirst = true
      let iPartSum = 0
      const tableData = []

      const oEmptyRow = { row_type: 'empty-row', title: '', average: 0, total: 0 }
      let oPartSumRow = { title: this.$t('common.subresult'), total: 0, average: 0, row_type: 'subtotal', min: 0, max: 0 }
      const oTotalRow = Object.assign({ title: this.$t('common.sum'), row_type: 'total' }, apiData.totals[accountType])

      let oAccountGroupRow = {}
      let oAccountRow = {}
      const oColumnLabels = {}

      // Sort account_groups and accounts before we begin
      // code goes here...

      // Create column label object to use for account group headers
      for (const index in apiData.periods) {
        oColumnLabels[apiData.periods[index]] = this.translatePeriodColumnTitleIntoNiceName(apiData.periods[index], resolution)
      }
      oColumnLabels.total = this.$t('common.total')
      oColumnLabels.total_percentage = this.$t('common.total_percentage')
      oColumnLabels.average = this.$t('common.average')
      oColumnLabels.max = this.$t('common.max')
      oColumnLabels.min = this.$t('common.min')
      oColumnLabels.median = this.$t('common.median')
      oColumnLabels.std_dev = this.$t('common.std_dev')

      // Loop all groups
      for (const iGroupId in apiData.groups[accountType]) {
        if (bFirst) {
          bFirst = false
        } else {
          oPartSumRow.amount = iPartSum

          if (sTableTypeToBuild === 'accounts') {
            tableData.push({ ...oPartSumRow })
            tableData.push(oEmptyRow)
          }
          iPartSum = 0
        }

        // Create from API data
        oAccountGroupRow = Object.assign({ row_type: 'account_group', account_type: accountType }, apiData.groups[accountType][iGroupId])

        if (sTableTypeToBuild === 'account_groups') {
          for (const index in oAccountGroupRow.period_totals) {
            oAccountGroupRow[index] = oAccountGroupRow.period_totals[index]
            oAccountGroupRow[index] = oAccountGroupRow.period_totals[index]
          }
        } else if (sTableTypeToBuild === 'accounts') {
          oAccountGroupRow = { ...oAccountGroupRow, ...oColumnLabels } // copy and merge
        }

        // Finally add account group to table data array
        tableData.push({ ...oAccountGroupRow })

        // Loop all accounts in group
        if (sTableTypeToBuild === 'accounts') {
          for (const iAccountId in apiData.groups[accountType][iGroupId].accounts) {
            const oAccount = apiData.groups[accountType][iGroupId].accounts[iAccountId]
            oAccountRow = Object.assign({ row_type: 'account', account_type: accountType }, oAccount)

            // Loop all periods in account
            for (const index in oAccount.period_totals) {
              oAccountRow[index] = oAccount.period_totals[index]
            }

            // Add to table
            tableData.push({ ...oAccountRow })
          }
        }

        // Create from API data
        oPartSumRow = Object.assign({ title: this.$t('common.partsum'), row_type: 'part-sum' }, apiData.groups[accountType][iGroupId])

        // Loop all account-group period totals
        for (const index in apiData.groups[accountType][iGroupId].period_totals) {
          oPartSumRow[index] = apiData.groups[accountType][iGroupId].period_totals[index]
        }
        // Loop all accounttype  totals
        for (const index in apiData.totals[accountType].period_totals) {
          oTotalRow[index] = apiData.totals[accountType].period_totals[index]
        }
      }
      // add last rows
      if (sTableTypeToBuild === 'accounts') {
        tableData.push({ ...oPartSumRow })
        tableData.push({ ...oEmptyRow })
      }
      tableData.push({ ...oTotalRow })
      return tableData
    },
    buildTableColumns (sTableTypeToBuild, isLoaded, apiData, resolution) {
      if (!this.isLoaded) { return [] }
      const aColumns = [{ key: 'title', label: '', sortable: false, class: 'text-left' }]

      for (const sPeriod in this.apiData.periods) {
        aColumns.push({ key: '' + this.apiData.periods[sPeriod], label: this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution).toString(), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.total.isActive) {
        aColumns.push({ key: 'total', label: this.$t('common.total'), sortable: false, class: 'text-left text-md-right td-total-column', formatter: this.amountFormatter })
      }
      if (this.options.total_percentage.isActive) {
        aColumns.push({ key: 'total_percentage', label: this.$t('common.total_percentage'), sortable: false, class: 'text-left text-md-right td-total-column', formatter: this.percentageFormatter })
      }
      if (this.options.avg_and_std_dev.isActive) {
        aColumns.push({ key: 'average_with_std_dev', label: this.$t('common.average') + ' ± ' + this.$t('common.std_dev'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.avg.isActive) {
        aColumns.push({ key: 'average', label: this.$t('common.average'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.std_dev.isActive) {
        aColumns.push({ key: 'std_dev', label: this.$t('common.std_dev'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.median.isActive) {
        aColumns.push({ key: 'median', label: this.$t('common.median'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.min.isActive) {
        aColumns.push({ key: 'min', label: this.$t('common.min'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      if (this.options.max.isActive) {
        aColumns.push({ key: 'max', label: this.$t('common.max'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }
      return aColumns
    },
    tableRowSetClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    },
    exportReportDataToCsv () {
      let sData = this.$t('reports.performance.performance-over-time.table.result.export_transactions_csv_title', { start_date: this.startDate, end_date: this.endDate }) + '\n\n'
      let sPeriods = ''

      for (const sPeriod in this.apiData.periods) {
        sPeriods += this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution) + ';'
      }
      sData += this.$t('reports.performance.performance-over-time.table.result.export_transactions_csv_heading', { periods: sPeriods }) + '\n'
      let oRow = null

      // console.log(this.tableData)
      for (const idx in this.tableData) {
        oRow = this.tableData[idx]
        switch (oRow.row_type) {
          case 'account_group':
          case 'account-group':
            sData += '\n' + oRow.title + ';\n'
            break
          case 'account':
            // Category;{periods}Sum;Average;Median;Std. deviation;Min;Max
            sData += oRow.title + ';'
            for (const sPeriod in this.apiData.periods) {
              sData += parseInt(oRow[this.apiData.periods[sPeriod]]) + ';'
            }
            sData += parseInt(oRow.total) + ';' + parseInt(oRow.average) + ';' + parseInt(oRow.median) + ';' + parseInt(oRow.std_dev) + ';' + parseInt(oRow.min) + ';' + parseInt(oRow.max) + ';\n'
            break
        }
      }
      // console.log(sData)
      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-' + (new Date().toISOString().split('T')[0]) + '.csv')
      document.body.appendChild(link)
      link.click()
    }
  }
}
