<template>
  <div class="component-reports-performance-over-time">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="bIsFilterReady"
                :default-period-type="'rolling-3-months'"
                :allowed-resolutions="['month', 'year']"
                :default-resolution="resolution"
                :showResolution="true"
                :showPeriodOptionFullPeriod="true"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters :class="{'pb-3': activeTab === 'filter'}">
          <b-col no-gutters>
            <performance-over-time-report-submenu class="overview" :active="activeTab" @tab-switched="onTabSwitched" ref="performanceMenu"></performance-over-time-report-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" style="width:100%;height:500px;" v-if="activeTab !== 'filter'" >
          <!-- Result charts -->
          <performance-over-time-report-chart-result v-if="activeTab === 'result' && result_tab_chart_to_display === 'result-bar-chart'" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution"></performance-over-time-report-chart-result>
          <performance-over-time-report-chart-result-acc v-if="activeTab === 'result' && result_tab_chart_to_display === 'result-area-chart-acc'" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution"></performance-over-time-report-chart-result-acc>
          <performance-over-time-report-chart-result-grouped v-if="activeTab === 'result' && result_tab_chart_to_display === 'result-grouped-bar-chart'" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" />

          <!-- Group- and account-charts (income / expense)  -->
          <performance-over-time-report-chart-account-groups :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'group-bar-chart'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="per_month"/>
          <performance-over-time-report-chart-account-groups :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'group-bar-chart-per-account-group'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="per_account_group"/>
          <performance-over-time-report-chart-account-groups-area :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'group-area-chart'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="amount" />
          <performance-over-time-report-chart-account-groups-area :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'group-area-chart-percentage'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="percentage" />
          <performance-over-time-report-chart-account-type :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'account-type-chart'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" />
          <performance-over-time-report-chart-account-type-stacked :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'account-type-chart-stacked'" accountResolution="account_group" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" />
          <performance-over-time-report-chart-account-type-stacked :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'account-type-chart-stacked-accounts'" accountResolution="account" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" />
          <performance-over-time-report-chart-accounts :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'accounts-bar-chart'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="per_month"/>
          <performance-over-time-report-chart-accounts :key="renderKey" v-if="( activeTab === 'income' || activeTab === 'expense') && account_type_tab_chart_to_display === 'accounts-bar-chart-per-account'" :activeTab="activeTab" :currency="currency" :currencySymbol="currencySymbol" :apiData="apiData" :isLoaded="!busyState" :resolution="this.resolution" chartType="per_account"/>
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0">
      <!-- Table components here -->
      <performance-over-time-report-table-result v-if="activeTab === 'result'" :activeTab="activeTab" :apiData="apiData" :startDate="sendDateAsParam('period_start')" :endDate="sendDateAsParam('period_end')" :isLoaded="!busyState" :resolution="this.resolution" :options="toggleColumnsOptions" :selected-chart="result_tab_chart_to_display"  @click-title="onTabChanged" @column-toggle="onColumnToggle"></performance-over-time-report-table-result>

      <performance-over-time-report-table-accounts v-if="activeTab === 'income'" :accountType="'income'" :apiData="apiData" :startDate="sendDateAsParam('period_start')" :endDate="sendDateAsParam('period_end')" :isLoaded="!busyState" :resolution="this.resolution" :options="toggleColumnsOptions" :selected-chart="account_type_tab_chart_to_display" @column-toggle="onColumnToggle"></performance-over-time-report-table-accounts>
      <performance-over-time-report-table-accounts v-if="activeTab === 'expense'" ref="AccountsTableExpense" :accountType="'expense'" :apiData="apiData" :startDate="sendDateAsParam('period_start')" :endDate="sendDateAsParam('period_end')" :isLoaded="!busyState" :resolution="this.resolution" :options="toggleColumnsOptions" :selected-chart="account_type_tab_chart_to_display" @column-toggle="onColumnToggle"></performance-over-time-report-table-accounts>

      <report-filter
        v-show="activeTab === 'filter'"
        ref="ReportFilter"
        cache-state
        :filter-data="reportFilterData"
        @filter-applied="handleEmittedFilterAccounts"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
        @ready="bIsFilterReady = true"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import PerformanceOverTimeReportSubmenu from './inc/PerformanceOverTimeReportSubmenu'
import PeriodSelector from '@/components/common/PeriodSelector'

import PerformanceOverTimeReportChartResult from './charts/PerformanceOverTimeReportChartResult'
import PerformanceOverTimeReportChartResultAcc from './charts/PerformanceOverTimeReportChartResultAcc'
import PerformanceOverTimeReportChartResultGrouped from './charts/PerformanceOverTimeReportChartResultGrouped'
import PerformanceOverTimeReportChartAccountGroups from './charts/PerformanceOverTimeReportChartAccountGroups'
import PerformanceOverTimeReportChartAccountGroupsArea from './charts/PerformanceOverTimeReportChartAccountGroupsArea'
import PerformanceOverTimeReportChartAccounts from './charts/PerformanceOverTimeReportChartAccounts'
import PerformanceOverTimeReportChartAccountType from './charts/PerformanceOverTimeReportChartAccountType'
import PerformanceOverTimeReportChartAccountTypeStacked from './charts/PerformanceOverTimeReportChartAccountTypeStacked'

import PerformanceOverTimeReportTableResult from './tables/PerformanceOverTimeReportTableResult'
import PerformanceOverTimeReportTableAccounts from './tables/PerformanceOverTimeReportTableAccounts'

import ReportFilter from '@/components/common/ReportFilter'

export default {
  name: 'Performance12Month',
  mixins: [titleMixins],
  components: {
    PerformanceOverTimeReportChartResultAcc,
    PerformanceOverTimeReportTableResult,
    PerformanceOverTimeReportChartAccountGroups,
    PerformanceOverTimeReportTableAccounts,
    PerformanceOverTimeReportSubmenu,
    PeriodSelector,
    PerformanceOverTimeReportChartResult,
    PerformanceOverTimeReportChartAccounts,
    PerformanceOverTimeReportChartAccountGroupsArea,
    PerformanceOverTimeReportChartResultGrouped,
    PerformanceOverTimeReportChartAccountType,
    PerformanceOverTimeReportChartAccountTypeStacked,
    ReportFilter
  },
  data () {
    return {
      renderKey: 0,
      validRouteParams: ['result', 'income', 'expense', 'filter'],
      activeTab: 'result',
      apiData: null,
      translationPath: 'reports.performance.performance-over-time.',
      busyState: true,
      period_start: null,
      period_end: null,
      resolution: 'month',
      include_accounts: '',
      bIsFilterApplied: false,
      bIsFilterReady: false,
      toggleColumnsOptions: {
        total: { label: 'common.total', isActive: true },
        total_percentage: { label: 'common.total_percentage', isActive: true },
        avg: { label: 'common.average', isActive: true },
        std_dev: { label: 'common.std_dev', isActive: false },
        avg_and_std_dev: { label: 'common.avg_and_std_dev', isActive: false },
        median: { label: 'common.median', isActive: false },
        min: { label: 'common.min', isActive: false },
        max: { label: 'common.max', isActive: false }
      },
      reportFilterData: null,
      result_tab_chart_to_display: 'result-bar-chart',
      account_type_tab_chart_to_display: 'account-type-chart'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title', { start_date: this.period_start, end_date: this.period_end })
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    handleEmittedFilterAccounts (sFilteredAccounts) {
      this.include_accounts = sFilteredAccounts
      this.bIsFilterApplied = true
      this.loadData()
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    clearFilter () {
      this.include_accounts = ''
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
    },
    loadingDataToast () {
      this.$bvToast.toast(this.$t(this.translationPath + 'toasts.loading.description').toString(), {
        title: this.$t(this.translationPath + 'toasts.loading.title').toString(),
        variant: 'info',
        solid: true
      })
    },
    sendDateAsParam (value) {
      if (this.busyState) {
        return ''
      }
      return this[value].toISOString().split('T')[0]
    },
    onTabChanged (data) {
      this.$refs.performanceMenu.changeTab(data)
      this.setPageTitle(this.titleMeta)
    },
    async loadData () {
      if (!this.bIsFilterReady) {
        return false
      }
      this.busyState = true

      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/reports/performanceOverTime?resolution=' + this.resolution + '&start_date=' + this.period_start.toISOString().split('T')[0] + '&end_date=' + this.period_end.toISOString().split('T')[0] + (this.include_accounts !== '' ? this.include_accounts : '')
      await axios({ method: 'get', url: sApiEndpoint })
        .then((response) => {
          this.apiData = response.data.data
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        })

      this.busyState = false
    },
    onTabSwitched (data) {
      this.activeTab = data.tab
      if (data.tab !== this.$route.params.tab) {
        const oRouteParams = { params: { tab: data.tab } }
        if (data.hash) {
          oRouteParams.hash = data.hash
        }
        // this.account_type_tab_chart_to_display = 'account-type-chart'
        this.renderKey++
        this.$router.push(oRouteParams)
      }
    },
    onPeriodChange (data) {
      this.period_start = data.oDateStart
      this.period_end = data.oDateEnd
      this.resolution = data.resolution

      this.loadData()
    },
    onGroupTitleClicked (group) {
      console.log(group) // todo: check
      this.onTabChanged({ tab: group.type, hash: '#group-' + group.id })
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    onColumnToggle (data) {
      this.toggleColumnsOptions = data.columns
    }
  },
  mounted () {
    this.activeTab = this.$route.params.tab
    this.$refs.performanceMenu.setActiveTabValue(this.activeTab)
    this.$root.$on('chart-to-display', (sChartToDisplayObject) => {
      switch (sChartToDisplayObject.from_tab) {
        case 'result':
          this.result_tab_chart_to_display = sChartToDisplayObject.chart
          break
        case 'account_types':
          this.account_type_tab_chart_to_display = sChartToDisplayObject.chart
          break
      }
    })
    this.$root.$on('column-to-toggle-emit', (sColumnName) => {
      this.toggleColumnsOptions[sColumnName] = !this.toggleColumnsOptions[sColumnName]
    })
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.onTabChanged({ tab: this.$route.params.tab })
    }
    this.loadingDataToast()
  },
  destroyed () {
    this.$root.$off('column-to-toggle-emit')
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
        this.onTabChanged({ tab: this.$route.params.tab })
      }
    }
  }
}
</script>

<style lang="scss">
.component-reports-performance-over-time{
  width: 100%;

  .performance-over-time-table{
    td {
      font-size: 100%;
    }

    /* Rows */
    tr.tr-account-group {
      td{
        background: white;
        border-top:0;
        padding-top:15px;
        border-bottom: 1px solid #949191;
        font-size:90%;
      }
      td:first-child {
        font-size:110%;
      }
    }

    tr.tr-account {
      td:first-child {
        font-weight: 400
      }
    }

    tr.tr-part-sum td {
      font-weight: 900;
      border-top: 1px solid #949191;
      border-bottom: 1px solid #949191;
    }

    tr.tr-empty-row td {
      background: white;
    }

    tr.tr-total{
      td {
        font-weight: 900;
        border-top: 1px solid black;
        border-bottom: 2px solid black;
      }
    }

    tr.tr-total-acc {
      td {
        border-bottom: 2px solid black;
      }
    }

    /* Columns */
    td.td-total-column {
      border-left: 1px solid black;
    }
  }
}
</style>
